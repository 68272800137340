<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Investor Charter - Stock Brokers</p>
                <p class="white--text headline">Investor Charter - Stock Brokers
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Investor Charter - Stock Brokers</p>
                <p class="white--text fs-18">Investor Charter - Stock Brokers
                </p>
            </v-card>
        </div>
        <div class="pa-6 px-sm-8 px-md-14 py-md-16 mb-6">
            <p class="mb-0 font-weight-bold title"> 1) Vision statement for Investors</p>
            <p class="mb-0 font-weight-bold title ml-6 mt-1">Mission</p>

            <p class="font-weight-light lh-28 ml-6 ">
            <ul type="i">
                <li>To provide transparent, equitable and reliable markets with timely and accurate
                    information dissemination for investors.</li>
                <li>To provide the highest standards of investor education, investor awareness and
                    investor protection and timely services.</li>
            </ul>
            </p>
            <p class="mb-0 font-weight-bold title ml-6 mt-1">Vision</p>
            <p class="font-weight-light lh-28 ml-6 ">To provide a safe, equitable, transparent, and trusted platform for
                investors to
                participate across asset classes with highest standards of integrity for investors.</p>

            <p class="mb-0 font-weight-bold title mt-3"> 2) Business transacted by the Exchange with investors:
            </p>

            <p class="font-weight-light lh-28 ml-6 ">The Exchange facilitates various products for investors to
                participate across
                asset classes viz Equity, Commodities, Derivatives, Debt, Mutual Funds,
                Government securities etc. <br> <a href="https://www.nseindia.com/products-services"
                    target="_blank">NSE</a>
                <a class="ml-4" href="https://www.bseindia.com/markets.html" target="_blank">BSE</a>
                <a class="ml-4" href="https://www.mcxindia.com/products/" target="_blank">MCX</a>
            </p>

            <p class="mb-0 font-weight-bold title mt-3"> 3) Services provided by the Exchanges to investors:
            </p>
            <p class="font-weight-light lh-28 ml-6 ">Exchange provides various services to investors electronically
                through its online
                platform and physically through the dedicated common Investor Service Centers
                (ISC) set for this purpose.<br>
                <a href="https://nsearchives.nseindia.com/web/sites/default/files/inline-files/SERVICES_PROVIDED_BY_THE_EXCHANGES_TO_INVESTORS.pdf"
                    target="_blank">NSE</a>
                <a class="ml-4" href="https://www.bseindia.com/investor.html" target="_blank">BSE</a>
                <a class="ml-4"
                    href="https://www.mcxindia.com/docs/default-source/investor-grievance-arbitration/investors-charter/services-to-investors.pdf?sfvrsn=bb083191_2"
                    target="_blank">MCX</a>

            </p>

            <p class="mb-0 font-weight-bold title mt-3"> 4) Grievance redressal mechanism:
            </p>
            <p class="font-weight-light lh-28 mb-0 ml-6 ">(1) Mode of filing the complaints – Complaints can be lodged
                on the Exchange in
                the following ways:</p>
            <p class="font-weight-light lh-28 ml-11 mb-0">(i) Through SCORES 2.0 (a web based centralized grievance
                redressal
                system of SEBI) [ <a href="https://scores.sebi.gov.in" target="_blank">https://scores.sebi.gov.in</a> ]
            </p>
            <p class="font-weight-semibold subtile lh-28 ml-11 mb-0 mt-1"><u>Two Level Review:</u></p>
            <p class="font-weight-light lh-28 ml-14 mb-0"> a. First review done by Exchange <br> b. Second review done
                by SEBI
            </p>

            <p class="font-weight-light lh-28 ml-11 mb-0"> (ii) Respective Exchange’s web portal dedicated for the
                filing of compliant
                <br>
                <a href="https://www.nseindia.com/complaints/complaint-at-exchange" class="ml-5" target="_blank">NSE</a>
                <a class="ml-4" href="https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx"
                    target="_blank">BSE</a>
                <a class="ml-4" href="https://www.mcxindia.com/Investor-Services" target="_blank">MCX</a>


            </p>
            <p class="font-weight-light lh-28 ml-11 mb-1">
                (iii) Emails to designated email IDs of Exchange
                <br>
                <a class="ml-5" href="https://www.nseindia.com/contact/investor-services-centre" target="_blank">NSE</a>
                <a class="ml-4" href="https://www.bseindia.com/static/investors/cac_tm.aspx" target="_blank">BSE</a>
                <a class="ml-4" href="https://www.mcxindia.com/Investor-Services/grievances/address-of-the-igrc-centres"
                    target="_blank">MCX</a>


            </p>
            <p class="font-weight-light lh-28 ml-11 mb-2">
                (iv) Through SMARTODR
                [ <a href="https://smartodr.in/login" target="_blank">https://smartodr.in/login</a> ]
                dedicated for the filing of complaint.
            </p>

            <p class="font-weight-light lh-28 ml-6 mb-1">
                (2) Regarding documents required for complaint resolution,
                Documents required for complaint resolution:

            </p>
            <p class="font-weight-light ml-8 mt-1 lh-28">
            <ul>
                <li> Complete Account Opening Kit</li>
                <li>Contract notes</li>
                <li> Ledger statement</li>
                <li>Bank statement</li>
                <li>Demat transaction statement</li>
                <li>Any other document in support of claim</li>
            </ul>
            </p>
            <p class="font-weight-light lh-28 ml-6 mb-1">
                (3) Process flow for Complaint Resolution Process through Scores 2.0
            </p>

            <div class="">
                <img src="@/assets/Compliance/inverstorstock.svg" width="70%" alt="InvestorStockBrokers">
            </div>

            <p class="font-weight-semibold lh-28 ml-6 mb-1">
                Online Dispute Resolution (ODR)
            </p>

            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">

                <div v-for="(l, j) in StockBrokActivitesnew" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.id
                                }}</span></v-col>
                        <v-col cols="4"><span class="font-weight-regular txt-444" v-html="l.activity"></span></v-col>
                        <v-col cols="8" md="7"><span class="font-weight-regular txt-444"
                                v-html="l.expectedtime"></span></v-col>
                    </v-row>
                    <v-divider v-if="StockBrokActivitesnew.length - 1 != j"></v-divider>
                </div>

            </v-card>

            <p class="mb-0 font-weight-bold title"> 5. Rights and Obligations of investors: </p>

            <p class="font-weight-light lh-28 mb-0 mt-1 ml-6 ">a) Investor Rights </p>

            <p class="font-weight-light ml-8 mt-1 lh-28">
            <ul>
                <li>Get a copy of KYC and other documents executed.</li>
                <li>Get Unique Client Code (UCC) allotted.</li>
                <li>Place order on complying with the norms agreed to with the Trading Member (TM).</li>
                <li>Get best price for trade execution.</li>
                <li>Receive various SMS, emails, and information from TMs regarding trade confirmations.</li>
                <li>Get Contract notes for trades executed from the TM in the specified format given by the Exchange
                    showing transaction price, brokerage, GST, and STT etc., as applicable, separately, within 24 hours
                    of your trades.</li>
                <li>Receive funds and securities/commodities on time within 24 hours from payout.</li>
                <li>Receive statement of accounts from TM at least once in a quarter/month from your TM.</li>
                <li>Settlement of accounts as per terms of agreement.</li>
                <li>Get the details of Principal Officer/Compliance Officer of the TM.</li>
                <li>Get information of all the businesses done by the TM.</li>
                <li>Receive all benefits/material information declared for the investors by the Company.</li>
                <li>Prompt services from the company such as transfers, dematerialization, Sub-divisions, and
                    consolidation of holdings in the company.</li>
                <li>As an equity holder, have a right to subscribe to further issue of capital by the Company.</li>
                <li>Approach nearest Common Investor Service Centre’s for lodging a complaint.</li>
                <li>Complaint and avail dispute resolution mechanism against TM or listed company.</li>
                <li>Raise queries on excess brokerage and other charges charged by TMs.</li>
                <li>File arbitration against TMs for disputes.</li>
                <li>Challenge the arbitration award before court of law.</li>
                <li>Privacy and Confidentiality.</li>
                <li>Fair & True Advertisement – Potential Risks to be clarified.</li>
                <li>Exit from financial product or service.</li>
                <li>Receive clear guidance and caution notice when dealing in Complex and High-Risk Financial Products
                    and Services.</li>
                <li>Provide feedback on the financial products and services used.</li>
            </ul>

            </p>

            <p class="font-weight-light lh-28 mb-0 mt-1 ml-6 ">b) Investor obligations/ Responsibilities </p>


            <p class="font-weight-light ml-8 mt-1 lh-28">
            <ul>
                <li>Deal with a SEBI registered Stock Brokers and Depository Participants for opening trading account
                    and demat account.</li>
                <li>Provide complete documents for account opening and KYC (Know Your Client). Fill all the required
                    details in Account Opening Form / KYC form in own handwriting and cancel out the blanks.</li>
                <li>Read all documents and conditions being agreed before signing the account opening form.</li>
                <li>Accept the Delivery Instruction Slip (DIS) book from DP only (pre-printed with a serial number along
                    with client ID) and keep it in safe custody and do not sign or issue blank or partially filled DIS.
                </li>
                <li>Always mention the details like ISIN, number of securities accurately.</li>
                <li>Inform any change in information for updation of KYC and obtain confirmation of updation in the
                    system.</li>
                <li>Regularly verify balances and transaction/demat statement and reconcile with trades/transactions.
                </li>
                <li>Appoint nominee(s) to facilitate heirs.</li>
                <li>Do not fall prey to fraudsters sending emails and SMSs luring to trade in stocks/securities
                    promising huge profits.</li>
            </ul>
            </p>

            <p class="mb-0 font-weight-bold title">6. Guidance pertaining to special circumstances related to market
                activities:
                Default of Trading Members (TMs)
            </p>
            <p class="font-weight-light lh-28 mb-0 mt-1 ml-6 ">(1) When a TMs defaults, the Exchange carries out the
                following steps for benefit
                of investor:</p>

            <p class="font-weight-light ml-8 mt-1 lh-28">
            <ul>
                <li>Dissemination on Exchange website with regard to default of the TM.</li>
                <li>Issue of Public Notice informing declaration of default by a TM and inviting claims within a
                    specified period.</li>
                <li>Intimation to clients of defaulter TMs via appropriate channels.</li>
            </ul>
            </p>
            <p class="font-weight-light lh-28 mb-0 mt-1 ml-6 ">(2) Following information made available on Exchange
                Website for information of
                Investors </p>
            <p class="font-weight-light ml-8 mt-1 lh-28">
            <ul>
                <li>Norms for eligibility of claims for compensation from IPF.</li>
                <li>FAQ on processing of investors’ claims against defaulter TM.</li>
                <li>Form for lodging claim against defaulter TM.</li>
                <li>Standard Operating Procedure (SOP) for handling of Claims of Investors in the cases of default by
                    TMs.</li>
                <li>Provision to check online status of claims on Exchange Website.</li>
                <li>Claim processing policy against Defaulter/Expelled TMs.</li>
                <li>List of Defaulter/Expelled TMs and public notice issued.</li>
            </ul>
            </p>
            <p class="font-weight-light lh-28 mb-0 mt-1 ml-6 ">(3) Standard Operating Procedure (SOP) for Handling of
                Claims of Investors in
                the Cases of Default by TMs (excluding Commodity Derivatives Exchanges) </p>

            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="1" class="d-none d-md-block"><span
                            class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="4"><span class="font-weight-bold subtitle-1">Action</span></v-col>
                    <v-col cols="8" md="7"><span class="font-weight-bold subtitle-1">Timeline</span></v-col>
                </v-row>
                <v-divider></v-divider>

                <div v-for="(l, j) in StockBrokActivites" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.id
                                }}</span></v-col>
                        <v-col cols="4"><span class="font-weight-regular txt-444" v-html="l.activity"></span></v-col>
                        <v-col cols="8" md="7"><span class="font-weight-regular txt-444"
                                v-html="l.timeline"></span></v-col>
                    </v-row>
                    <v-divider v-if="StockBrokActivites.length - 1 != j"></v-divider>
                </div>

            </v-card>
            <p class="mb-0 font-weight-bold title">7. Dos and Don’ts - Advisory for Investors

            </p>

            <div class="ml-6">
                <v-btn data-aos="fade-up" @click="act = p" v-for="(p, b) in products" :key="b" medium
                    :color="act == p ? '#000000' : '#666666'" class="rounded-pill mr-2 mt-4  text-none brd-2 mb-4"
                    outlined>
                    <span class="font-weight-black fs-17">{{ p }}</span>
                </v-btn>

            </div>
            <div v-if="act == 'NSE' || act == 'BSE'">
                <p class="mb-0 font-weight-semibold mb-1">a) Do’s of Investing:</p>

                <p class="font-weight-light ml-8 mt-1 lh-28">

                <ul>
                    <li>Deal only with registered intermediaries - check the registration certificate of the
                        intermediary
                        you are dealing with. It allows recourse to regulatory action.</li>
                    <li>Read all mandatory documents viz. Rights and Obligations, Risk Disclosure Document, Policy and
                        Procedure document of the stockbroker.</li>
                    <li>Be informed about brokerage, commissions, fees, other charges levied by broker.</li>
                    <li>Read, understand and then sign the voluntary clauses.</li>
                    <li>Check for all conditions that have been agreed and accepted by you.</li>
                    <li>Ensure to fill all the required details in “Account Opening Form” / Know Your Client Form (KYC)
                        by
                        yourself and receive duly signed copy of your ‘KYC’ documents from your stock broker. Always
                        keep
                        your contact details viz Mobile number / Email ID updated with the stock broker. You may take up
                        the
                        matter with Stock Broker / Exchange if you are not receiving the messages from Exchange /
                        Depositories regularly.</li>
                    <li>Opt for electronic (e-mail) contract notes/financial statements only if you are computer savvy
                        and
                        have an e-mail account of your own.</li>
                    <li>Ensure that pay-out of funds/securities/commodities is received in your account within 1 working
                        day
                        from the date of pay-out.</li>
                    <li>Make payments only through the banking channel and issue cheque in favour of stock broker only.
                    </li>
                    <li>Trade verification facility is also available on Exchange website which you can use to verify
                        your
                        trades.</li>
                    <li>Be careful while executing the PoA (Power of Attorney) - specify all the rights that the stock
                        broker can exercise and timeframe for which PoA is valid. It may be noted that PoA is not a
                        mandatory requirement as per SEBI / Exchanges.</li>
                    <li>Register for online applications viz Speed-e and Easiest provided by Depositories for online
                        delivery of securities as an alternative to PoA.</li>
                    <li>Ensure that you receive Contract Notes within 24 hours of your trades and Statement of Account
                        at
                        least once in a quarter / month from your Stock Broker.</li>
                    <li>If you have opted for running account, please ensure that the stock broker settles your account
                        regularly and in any case not later than 90 days (or 30 days if you have opted for 30 days
                        settlement).</li>
                    <li>Regularly login into your account to verify balances and verify the demat statement received
                        from
                        depositories for correctness. Regularly verify Consolidated Accounts Statement (CAS) received
                        from
                        Depositories and reconcile with your trades / transactions.</li>
                    <li>Keep Delivery Instruction Slip (DIS) of your Demat account safely. Do not hand over blank signed
                        DIS
                        slips to any-one.</li>
                    <li>Check messages sent by Exchanges on a weekly basis regarding funds / securities / commodities
                        balances reported by the stock broker and immediately raise a concern, if you notice a
                        discrepancy.
                    </li>
                    <li>If you observe any discrepancies in your account or settlements, immediately take up the same
                        with
                        your stock broker in writing within 7 (seven) working days from date of receipt of the
                        statement. If
                        the Stock Broker does not respond, take up the matter with the Exchange/Depositories.</li>
                    <li>If in doubt, revoke any authorization given by you at any time.</li>
                    <li>Beware of fixed/guaranteed returns schemes. Brokers or any of their representatives are not
                        authorized to offer fixed/guaranteed returns on your investment or enter into any loan agreement
                        to
                        pay interest on the funds/securities/commodities offered by you.</li>
                    <li>Do your own study about the fundamentals of the company whose shares you are buying.</li>
                    <li>Attend various Investor Awareness Programs held by SEBI/ Exchanges/ Depositories for awareness
                        of
                        various changes in the markets.</li>
                </ul>
                </p>
                <p class="mb-0 font-weight-semibold mb-1">Relating to Mutual Funds:</p>
                <p class="font-weight-light ml-8 mt-1 lh-28">
                <ul>
                    <li>Gauge your risk profile based on income, cash flow, ability to sustain financial risks before
                        investing in a fund.</li>
                    <li>Work out your asset allocation based on your risk profile like investing in equity / debt /
                        ETFs,
                        etc.</li>
                    <li>Use investment strategies like Systematic Investment Plans (SIPS) to create investing discipline
                        and
                        long term wealth.</li>
                    <li>Understand the Tax implications on investments, consulting tax advisor, if necessary.</li>
                    <li>Monitor your mutual fund investments keeping in mind your changing financial goals.</li>
                    <li>Consult a financial advisor to help you make the right investment decisions.</li>
                </ul>
                </p>

                <p class="mb-0 font-weight-semibold mb-1">b)Do’s for Grievance Redressal:</p>
                <p class="font-weight-light ml-8 mt-1 lh-28">
                <ul>
                    <li>File complaints within the limitation period to avoid rejection at the arbitration stage.</li>
                    <li>Take up complaint redressal with the Trading Member (TM) first and if not resolved within a
                        reasonable time frame, then approach Exchanges/ SEBI.</li>
                    <li>For faster resolution of complaints:
                        <ul>
                            <li>Lodge complaint with relevant exchange/ depository.</li>
                            <li>Lodge complaint online and with all relevant supporting documents.</li>
                            <li>Provide trading account details including client Code and PAN details at the time of
                                complaint registration.</li>
                            <li>Provide all information sought by the exchange expeditiously.</li>
                            <li>Maintain copies of all account opening documents and trade related information received
                                from
                                the trading member.</li>
                        </ul>
                    </li>
                </ul>
                </p>

                <p class="mb-0 font-weight-semibold mb-1">c) Don’ts of Investing:</p>
                <p class="font-weight-light ml-8 mt-1 lh-28">
                <ul>
                    <li>Do not share password (internet account) with anyone. It is like sharing your safe key.</li>
                    <li>Do not transfer Securities to your Stock Broker for the purpose of margin. They remain in your
                        account only and you need to only pledge them to your Stock Broker through the pledge mechanism.
                    </li>
                    <li>Do not transfer funds/securities, for the purposes of trading to anyone other than a registered
                        stock broker or Depository Participants with SEBI.</li>
                    <li>Don't ignore any emails/SMSs received with regards to trades done by you from the Exchange.
                        Verify
                        the same with the Contract notes/Statement of accounts received from your broker and report
                        discrepancy, if any, to your broker in writing immediately and if the stock broker does not
                        respond,
                        with the Exchange/Depositories.</li>
                    <li>Do not keep funds / securities / commodities idle with the Stock Broker.</li>
                    <li>Do not fall prey to fraudsters sending emails and SMSs luring to trade in stocks/ Securities
                        promising huge profits.</li>
                </ul>
                <span class="font-weight-semibold  mt-1 lh-28">Relating to Mutual Funds</span>
                <ul>
                    <li>Don’t take more risk than you can deal with, always analyse your risk profile before investment.
                    </li>
                    <li>Don’t invest all your money in one asset class or a particular type of fund or sector.</li>
                    <li>Don’t invest without guidance if you are not conversant with Mutual Fund investing.</li>
                    <li>Don’t be in a haste to invest without reading and understanding the contents of the scheme
                        related
                        documents.</li>
                    <li>Don’t handover unfilled or incomplete applications.</li>
                    <li>Don’t forget to save, always keep surplus funds aside for emergencies.</li>
                </ul>
                </p>
            </div>
            <div v-if="act == 'MCX'">
                <p class="mb-0 font-weight-semibold mb-1">a) Do’s of Investing:</p>

                <p class="font-weight-light ml-8 mt-1 lh-28">
                <ul>
                    <li>Deal only with registered intermediaries - check the registration certificate of the
                        intermediary you are dealing with. It allows recourse to regulatory action.</li>
                    <li>Read all mandatory documents viz. Rights and Obligations, Risk Disclosure Document, Policy and
                        Procedure document of the stockbroker.</li>
                    <li>Be informed about brokerage, commissions, fees, other charges levied by broker.</li>
                    <li>Read, understand and then sign the voluntary clauses.</li>
                    <li>Check for all conditions that have been agreed and accepted by you.</li>
                    <li>Ensure to fill all the required details in “Account Opening Form” / Know Your Client Form (KYC)
                        by yourself and receive duly signed copy of your ‘KYC’ documents from your stock broker. Always
                        keep your contact details viz Mobile number /Email ID updated with the stock broker. You may
                        take up the matter with Stock Broker / Exchange if you are not receiving the messages from
                        Exchange /Depositories regularly.</li>
                    <li>Opt for electronic (e-mail) contract notes/financial statements only if you are computer savvy
                        and have an e-mail account of your own.</li>
                    <li>Ensure that pay-out of funds/securities/commodities is received in your account within 1 working
                        day from the date of pay-out.</li>
                    <li>Make payments only through the banking channel and issue cheque in favour of stock broker only.
                    </li>
                    <li>Trade verification facility is also available on Exchange website which you can use to verify
                        your trades.</li>
                    <li>Be careful while executing the PoA (Power of Attorney) - specify all the rights that the stock
                        broker can exercise and timeframe for which PoA is valid. It may be noted that PoA is not a
                        mandatory requirement as per SEBI / Exchanges.</li>
                    <li>Register for online applications viz Speed-e and Easiest provided by Depositories for online
                        delivery of securities as an alternative to PoA.</li>
                    <li>Ensure that you receive Contract Notes within 24 hours of your trades and Statement of Account
                        at least once in a quarter / month from your Stock Broker.</li>
                    <li>If you have opted for running account, please ensure that the stock broker settles your account
                        regularly and in any case not later than 90 days (or 30 days if you have opted for 30 days
                        settlement).</li>
                    <li>Regularly login into your account to verify balances and verify the demat statement received
                        from depositories for correctness. Regularly verify Consolidated Accounts Statement (CAS)
                        received from Depositories and reconcile with your trades / transactions.</li>
                    <li>Keep Delivery Instruction Slip (DIS) of your Demat account safely. Do not hand over blank signed
                        DIS slips to anyone.</li>
                    <li>Check messages sent by Exchanges on a weekly basis regarding funds/securities/commodities
                        balances reported by the stock broker and immediately raise a concern, if you notice a
                        discrepancy.</li>
                    <li>If you observe any discrepancies in your account or settlements, immediately take up the same
                        with your stock broker broker in writing within 7 (seven) working days from date of receipt of
                        the statement. If the Stock Broker does not respond, take up the matter with the
                        Exchange/Depositories.</li>
                    <li>If in doubt, revoke any authorization given by you at any time.</li>
                    <li>Beware of fixed/guaranteed returns schemes. Brokers or any of their representatives are not
                        authorized to offer fixed/guaranteed returns on your investment or enter into any loan agreement
                        to pay interest on the funds/securities/commodities offered by you.</li>
                    <li>Do your own study about the fundamentals of the company whose shares you are buying.</li>
                    <li>Attend various Investor Awareness Programs held by SEBI/ Exchanges/Depositories for awareness of
                        various changes in the markets.</li>
                </ul>
                </p>
                <p class="mb-0 font-weight-semibold mb-1">b) Do’s for Grievance Redressal:</p>
                <p class="font-weight-light ml-8 mt-1 lh-28">
                <ul>
                    <li>File complaints within the limitation period to avoid rejection at the arbitration stage.</li>
                    <li>Take up complaint redressal with the Trading Member (TM) first and if not resolved within a
                        reasonable time frame, then approach Exchanges/ SEBI.</li>
                    <li>For faster resolution of complaints:</li>
                    <ul>
                        <li>Lodge complaint with relevant exchange/ depository.</li>
                        <li>Lodge complaint online and with all relevant supporting documents.</li>
                        <li>Provide trading account details including client Code and PAN details at the time of
                            complaint registration.</li>
                        <li>Provide all information sought by the exchange expeditiously.</li>
                        <li>Maintain copies of all account opening documents and trade related information received from
                            the trading member.</li>
                    </ul>
                </ul>
                </p>
                <p class="mb-0 font-weight-semibold mb-1">c) Don’ts of Investing:</p>
                <p class="font-weight-light ml-8 mt-1 lh-28">
                <ul>
                    <li>Do not share password (internet account) with anyone. It is like sharing your safe key.</li>
                    <li>Do not transfer Securities to your Stock Broker for the purpose of margin. They remain in your
                        account only and you need to only pledge them to your Stock Broker through the pledge mechanism.
                    </li>
                    <li>Do not transfer funds/securities, for the purposes of trading to anyone other than a registered
                        stock broker or Depository Participants with SEBI.</li>
                    <li>Don't ignore any emails/SMSs received with regards to trades done by you from the Exchange.
                        Verify the same with the Contract notes/Statement of accounts received from your broker and
                        report discrepancy, if any, to your broker in writing immediately and if the stock broker does
                        not respond, with the Exchange/Depositories.</li>
                    <li>Do not keep funds / securities / commodities idle with the Stock Broker.</li>
                    <li>Do not fall prey to fraudsters sending emails and SMSs luring to trade in stocks/Securities
                        promising huge profits.</li>
                </ul>
                </p>

            </div>

            <p class="mb-0 font-weight-bold title">8. Code of Conduct for Stock Exchanges
            </p>
            <p class="mb-0 font-weight-bold title ml-6 mt-3">A Stock Exchange shall:</p>
            <p class="font-weight-light ml-4 mt-1 lh-28">
            <ul style="list-style-type: none;">
                <li>
                    (a) Always abide by the provisions of the Act, Securities and Exchange Board of India Act 1992, any
                    Rules or Regulations framed thereunder, circulars, guidelines and any other directions issued by the
                    Board from time to time.
                </li>
                <li>
                    (b) Adopt appropriate due diligence measures.
                </li>
                <li>
                    (c) Take effective measures to ensure implementation of proper risk management framework and good
                    governance practices.
                </li>
                <li>
                    (d) Take appropriate measures towards investor protection and education of investors.
                </li>
                <li>
                    (e) Treat all its applicants or members in a fair and transparent manner.
                </li>
                <li>
                    (f) Promptly inform the Board of violations of the provisions of the Act, Securities and Exchange
                    Board of India Act 1992, rules, regulations, circulars, guidelines or any other directions by any of
                    its members or issuer.
                </li>
                <li>
                    (g) Take a proactive and responsible attitude towards safeguarding the interests of investors,
                    integrity of stock exchange’s systems and the securities market.
                </li>
                <li>
                    (h) Endeavor for introduction of best business practices amongst itself and its members.
                </li>
                <li>
                    (i) Act in utmost good faith and shall avoid conflict of interest in the conduct of its functions.
                </li>
                <li>
                    (j) Not indulge in unfair competition, which is likely to harm the interests of any other Exchange,
                    their participants or investors or is likely to place them in a disadvantageous position while
                    competing for or executing any assignment.
                </li>
                <li>
                    (k) Segregate roles and responsibilities of key management personnel within the stock exchange
                    including:
                    <ol type="a" class="ml-3">
                        <li>Clearly mapping legal and regulatory duties to the concerned position</li>
                        <li>Defining delegation of powers to each position</li>
                        <li>Assigning regulatory, risk management and compliance aspects to business and support
                            teams</li>
                    </ol>
                </li>
                <li>
                    (l) Be responsible for the acts or omissions of its employees in respect of the conduct of its
                    business.
                </li>
                <li>
                    (m) monitor the compliance of the rules and regulations by the participants and
                    shall further ensure that their conduct is in a manner that will safeguard the
                    interest of investors and the securities market.
                </li>
            </ul>
            </p>

        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        products: [
            'NSE',
            'BSE',
            'MCX'
        ],
        act: 'NSE',
        StockBrokActivites: [
            {
                "id": 1,
                "activity": "Disablement of the TM",
                "timeline": "T day"
            },
            {
                "id": 2,
                "activity": "Information to investors about disablement of the TM on website and through SMS and email",
                "timeline": "T+1 day"
            },
            {
                "id": 3,
                "activity": "Pre-filled forms to be sent to clients providing information regarding balances with the TM (only in case of SOP trigger)",
                "timeline": "T+15 days"
            },
            {
                "id": 4,
                "activity": "Claim lodgement - Clients to fill the claim form and provide the supporting documents",
                "timeline": "T+75 days"
            },
            {
                "id": 5,
                "activity": "Declaration of Default by the TM",
                "timeline": "T+120 days"
            },
            {
                "id": 6,
                "activity": "Information to investors about default of TM on website, through SMS, email and newspapers",
                "timeline": "Within 3 working days from the date of declaration of defaulter"
            },
            {
                "id": 7,
                "activity": "Processing, auditing, and settlement of claims",
                "timeline": "Within 60 days of receipt of the claim form from the clients post declaration of default and T+135 days where prefilled forms are received"
            },
            {
                "id": 8,
                "activity": "Request for review of the claim by client",
                "timeline": "Within 90 days of receipt of intimation of the decision of the IPFT from the stock exchange"
            },
            {
                "id": 9,
                "activity": "Processing, auditing, and settlement of review claims",
                "timeline": "Within 60 days of receipt of review application"
            },
            {
                "id": 10,
                "activity": "Request for 2nd review of the claim by client",
                "timeline": "Within 90 days of receipt of intimation of the decision of the IPFT from the stock exchange"
            },
            {
                "id": 11,
                "activity": "Processing, auditing, and settlement of review claims",
                "timeline": "Within 60 days of receipt of review application"
            }
        ],

        StockExchangeItems: [
            {
                id: '1',
                activite: "Receipt of Complaint",
                expectedtime: "Day of complaint (C Day).",
            },
            {
                id: '2',
                activite: "Additional information sought from the investor, if any, and provisionally forwarded to stock broker",
                expectedtime: "C + 7 Working days",
            },
            {
                id: '3',
                activite: "Registration of the complaint and forwarding to the stock broker.",
                expectedtime: "C+8 Working Days i.e. T day.",
            },
            {
                id: '4',
                activite: "Amicable Resolution.",
                expectedtime: "T+15 Working Days",
            },
            {
                id: '5',
                activite: "Refer to Grievance Redressal Committee (GRC), in case of no amicable resolution.",
                expectedtime: "T+16 Working Days.",
            },
            {
                id: '6',
                activite: "Complete resolution process post GRC.",
                expectedtime: "T + 30 Working Days",
            },
            {
                id: '7',
                activite: "In case where the GRC Member requires additional information, GRC order shall be completed within.",
                expectedtime: "T + 45 Working Days",
            },
            {
                id: '8',
                activite: "Implementation of GRC Order.",
                expectedtime: "On receipt of GRC Order, if the order is in favour of the investor, debit the funds of the stock broker. Order for debit is issued immediately or as per the directions given in GRC order.",
            },
            {
                id: '9',
                activite: "In case the stock broker is aggrieved by the GRC order, will provide intention to avail arbitration",
                expectedtime: "Within 7 days from receipt of order",
            },
            {
                id: '10',
                activite: "If intention from stock broker is received and the GRC order amount is upto Rs.20 lakhs",
                expectedtime: "Investor is eligible for interim relief from Investor Protection Fund (IPF).The interim relief will be 50%of the GRC order amount or Rs.2 lakhs whichever is less. The same shall be provided after obtaining an Undertaking from the investor.",
            },
            {
                id: '11',
                activite: "Stock Broker shall file for arbitration",
                expectedtime: "Within 6 months from the date of GRC recommendation",
            },
            {
                id: '12',
                activite: "In case the stock broker does not file for arbitration within 6 months",
                expectedtime: "The GRC order amount shall be released to the investor after adjusting the amount released as interim relief, if any",
            },
        ],
        DosDonts: [
            {
                id: '1',
                Dos: "Read all documents and conditions being agreed before signing the account opening form.",
                Donts: "Do not deal with unregistered stock broker.",
            },
            {
                id: '2',
                Dos: "Receive a copy of KYC, copy of account opening documents and Unique Client Code.	",
                Donts: "Do not forget to strike off blanks in your account opening and KYC.",
            },
            {
                id: '3',
                Dos: "Read the product / operational framework / timelines related to various Trading and Clearing & Settlement processes",
                Donts: "Do not submit an incomplete account opening and KYC form",
            },
            {
                id: '4',
                Dos: "Receive all information about brokerage, fees and other charges levied	",
                Donts: "Do not forget to inform any change in information linkedto trading account and obtain confirmation of updation in the system.",
            },
            {
                id: '5',
                Dos: "Register your mobile number and email ID in your trading, demat and bank accounts to get regular alerts on your transactions",
                Donts: "Do not transfer funds, for the purposes of trading to any one other than a stock broker. No payment should be made in name of employee of stock broker",
            },
            {
                id: '6',
                Dos: "If executed, receive a copy of Power of Attorney. However, Power of Attorney is not a mandatory requirement as per SEBI / Stock Exchanges. Before granting Power of Attorney, carefully examine the scope and implications of powers being granted.",
                Donts: "Do not ignore any emails / SMSs received with regards to trades done, from the Stock Exchange and raise a concern, if discrepancy is observed.",
            },
            {
                id: '7',
                Dos: "Receive contract notes for trades executed, showing transaction price, brokerage, GST and STT etc. as applicable, separately, within 24 hours of execution of trades.",
                Donts: "Do not opt for digital contracts,if not familiar with computers.",
            },
            {
                id: '8',
                Dos: "Receive funds and securities / commodities on time within 24 hours from pay-out.",
                Donts: "Do not share trading password.",
            },
            {
                id: '9',
                Dos: "Verify details of trades, contract notes and statement of account and approach relevant authority for any discrepancies. Verify trade details on the Exchange websites from the trade verification facility provided by the Exchanges.",
                Donts: "Do not fall prey to fixed / guaranteed returns schemes.",
            },
            {
                id: '10',
                Dos: "Receive statement of accounts periodically. If opted for running account settlement, account has to be settled by the stock broker as per the option given by the client (30 or 90 days).",
                Donts: "Do not fall prey to fraudsters sending emails and SMSs luring to trade in stocks / securities promising huge profits.",
            },
            {
                id: '11',
                Dos: "In case of any grievances, approach stock broker or Stock Exchange or SEBI for getting the same resolved within prescribed timelines.",
                Donts: "Do not follow herd mentality for investments. Seek expert and professional advice for your investments.",
            },
        ],
        StockBrokActivitesnew: [
            {
                id: "1",
                activity: "Online Dispute Resolution (ODR) platform for online Conciliation and Arbitration",
                expectedtime: "If the Investor is not satisfied with the resolution provided by the Market Participants, then the Investor has the option to file the complaint/grievance on the SMARTODR platform for its resolution through online conciliation or arbitration. [ <a href='https://smartodr.in' target='_blank'>SMARTODR</a> ]"
            },
            {
                id: "2",
                activity: "Steps to be followed in ODR for Review, Conciliation and Arbitration",
                expectedtime: `
      <ul>
        <li>Investor to approach Market Participant for redressal of complaint.</li>
        <li>
          If the investor is not satisfied with the response of the Market Participant, he/she has either of the following 2 options:
          <ol type="i">
            <li>May escalate the complaint on the SEBI SCORES portal. (In this scenario, the above steps shall prevail)</li>
            <li>May also file a complaint on the SMARTODR portal for its resolution through online conciliation and arbitration.</li>
          </ol>
        </li>
        <li>
          Upon receipt of a complaint on the SMARTODR portal, the relevant MII will review the matter and endeavour to resolve the matter between the Market Participant and investor within 21 days.
        </li>
        <li>If the matter could not be amicably resolved, then the matter shall be referred for conciliation.</li>
        <li>
          During the conciliation process, the conciliator will endeavour for an amicable settlement of the dispute within 21 days, which may be extended by 10 days with the consent of the parties to the dispute.
        </li>
        <li>
          If the conciliation is unsuccessful, then the investor may request to refer the matter for arbitration.
        </li>
        <li>
          The arbitration process is to be concluded by arbitrator(s) within 30 days, which is extendable by 30 days with the consent of the parties to the dispute.
        </li>
        <li>
          If the parties are not satisfied with the arbitration award, they have the option to file under Section 34 of the Arbitration and Conciliation Act, 1996.
        </li>
      </ul>
    `
            }
        ]
    }),
}
</script>