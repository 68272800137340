<template>
    <div class="no-xhide">
        <!-- <AppBar /> -->

        <div class="body-part">
            <v-row no-glutters>
                <v-col cols="12" md="9"
                    class="d-none d-md-block px-0   px-md-0 pt-4 pt-md-3 pb-0 pb-md-3 singpleiopsscrol">
                    <div class="ml-4">
                        <v-card :loading="screenloader" class="ss-cards mt-4 overflow-hidden mb-md-6" width="100%">
                            <v-card color="#FAFBFF" class="elevation-0 rounded-0 pt-2 px-md-2 px-3">
                                <v-row no-glutters>
                                    <v-col cols="12" md="7" class="pb-1 pb-md-2">
                                        <v-list-item class="px-0 px-md-4">
                                            <v-list-item-avatar contain color="#FFFFFF" size="56" class="my-0">
                                                <!-- <img  :src="imageicon"
                                                                @error="imageLoadError" class="pa-1" :alt="imageicon"
                                                                width="100%" />
                                                            <span class="headline font-weight-bold" v-else>{{
                                                                menudata[0] ?
                                                                    (menudata[0].cname ? menudata[0].cname :
                                                                        menudata[0].symname).slice(0, 1) : ''
                                                            }}</span> -->
                                                <img :src="singlepagedata.image_link" class="pa-1" width="100%" />
                                            </v-list-item-avatar>
                                            <v-list-item-content class="pt-3 pb-0">
                                                <v-list-item-title
                                                    class="fs-18 font-weight-bold mb-0 text-capitalize lh-14">
                                                    {{ singlepagedata['Company Name'] ? singlepagedata['Company Name'] :
                                                        ''
                                                    }}
                                                </v-list-item-title>
                                                <v-chip-group class="my-0 py-0">

                                                    <v-chip label small text-color="#666666" color="#fff"
                                                        class=" font-weight-medium fs-10">

                                                        {{ singlepagedata.ipo_type ? singlepagedata.ipo_type : ''
                                                        }}
                                                    </v-chip>
                                                </v-chip-group>
                                                <!-- <v-list-item-subtitle
                                                                class="fs-10 txt-666 font-weight-normal text-uppercase">{{(new Date().toString()).slice(4, 21) }}
                                                                IST</v-list-item-subtitle> -->
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <!-- <v-col cols="12" md="5"
                                        class="text-md-right text-left pt-0 pt-md-5 pb-2 pl-4 pl-md-3">
                                        <p class="mb-0 mr-md-4 subtitle-1 font-weight-bold lh-24">
                                            Open Price : {{
                                                singlepagedata.BSE !== 'no data'
                                                    ? `₹ ${singlepagedata.BSE.ClsPric}`
                                                    : singlepagedata.NSE !== 'no data'
                                                        ? `₹ ${singlepagedata.NSE.OpnPric}`
                                                        : '₹ 0.00'
                                            }}
                                            <br class="d-none d-md-block">
                                           
                                         

                                                            <span  class="fs-12"
                                                            :class="ipocalreturn > 0 ? 'txt-gre' : ipocalreturn < 0 ? 'txt-red' : 'txt-444'">
                                                            {{ ipocalreturn ? ipocalreturn : '0.00' }}
                                                            </span>
                                        </p>
                                    </v-col> -->
                                </v-row>
                            </v-card>
                            <div class="py-3 py-md-6">
                                <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent" dense>
                                    <p class="wid-160 font-weight-bold title mb-0">IPO details</p>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-row class="pt-md-0 pb-md-3 pl-md-6 pr-md-8 flex-column-reverse flex-md-row">
                                    <v-col cols="12" class="px-7 px-md-3">
                                        <v-row no-gluttters>
                                            <v-col cols="3">
                                                <v-list-item class="px-0">
                                                    <v-list-item-content class="pb-0">
                                                        <v-list-item-subtitle
                                                            class="font-weight-regular fs-10 txt-666 mb-2">Exchange
                                                        </v-list-item-subtitle>
                                                        <v-list-item-title
                                                            class="black--text font-weight-medium fs-12 mb-1">
                                                            {{
                                                                singlepagedata['Stock Exchanges']
                                                                    ? `${singlepagedata['Stock Exchanges']}`
                                                                    : "----"
                                                            }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </v-col>

                                            <!-- <v-col cols="4">
                                                <v-list-item class="px-0">
                                                    <v-list-item-content class="pb-0">
                                                        <v-list-item-subtitle
                                                            class="font-weight-regular fs-10 txt-666 mb-2">Min.
                                                            Investment
                                                        </v-list-item-subtitle>
                                                        <v-list-item-title
                                                            class="black--text font-weight-medium fs-12 mb-1">
                                                            <div>
                                                                {{
                                                                    singlepagedata['Price Range'] &&
                                                                        singlepagedata['LotSize']
                                                                        ? Number(singlepagedata['Price Range']) *
                                                                        Number(singlepagedata['Lot Size'].replace(/,/g, ''))
                                                                        : '---'
                                                                }}
                                                            </div>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </v-col> -->

                                            <v-col cols="3">
                                                <v-list-item class="px-0">
                                                    <v-list-item-content class="pb-0">
                                                        <v-list-item-subtitle
                                                            class="font-weight-regular fs-10 txt-666 mb-2">Lot Size
                                                        </v-list-item-subtitle>
                                                        <v-list-item-title
                                                            class="black--text font-weight-medium fs-12 mb-1">
                                                            {{
                                                                singlepagedata['Lot Size']
                                                                    ? `${singlepagedata['Lot Size']} shares / lot`
                                                                    : "---"
                                                            }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </v-col>

                                            <v-col cols="3">
                                                <v-list-item class="px-0">
                                                    <v-list-item-content class="pb-0">
                                                        <v-list-item-subtitle
                                                            class="font-weight-regular fs-10 txt-666 mb-2">Issue Size
                                                        </v-list-item-subtitle>
                                                        <v-list-item-title
                                                            class="black--text font-weight-medium fs-12 mb-1">
                                                            {{
                                                                singlepagedata['Issue Size']
                                                                    ? `${singlepagedata['Issue Size']} Cr`
                                                                    : "---"
                                                            }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </v-col>

                                            <v-col cols="3">
                                                <v-list-item class="px-0">
                                                    <v-list-item-content class="pb-0">
                                                        <v-list-item-subtitle
                                                            class="font-weight-regular fs-10 txt-666 mb-2">Price range
                                                        </v-list-item-subtitle>
                                                        <v-list-item-title
                                                            class="black--text font-weight-medium fs-12 mb-1">
                                                            {{
                                                                singlepagedata['Price Range']
                                                                    ? `${singlepagedata['Price Range']} `
                                                                    : "---"
                                                            }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </v-col>


                                            <!-- <v-col cols="4">
                                                <v-list-item class="px-0">
                                                    <v-list-item-content class="pb-0">
                                                        <v-list-item-subtitle
                                                            class="font-weight-regular fs-10 txt-666 mb-2">Total Traded
                                                            Value
                                                        </v-list-item-subtitle>
                                                        <v-list-item-title
                                                            class="black--text font-weight-medium fs-12 mb-1">
                                                            {{
                                                                singlepagedata.BSE?.TtlTradgVol
                                                                || (singlepagedata['Stock Exchanges'] === 'BSE, NSE' &&
                                                                    singlepagedata.NSE?.TtlTradgVol)
                                                                || singlepagedata.NSE?.TtlTradgVol
                                                                || '----'
                                                            }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                            </v-col> -->

                                        </v-row>
                                    </v-col>

                                </v-row>
                            </div>
                        </v-card>


                        <v-card class="ss-cards overflow-hidden mb-md-6" width="100%">
                            <div class="py-3 py-md-6">
                                <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent" dense>
                                    <v-list-item class="px-0">
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold title mb-2">IPO Timeline
                                            </v-list-item-title>

                                        </v-list-item-content>
                                    </v-list-item>
                                </v-toolbar>
                                <!-- <div>
                                    <v-row class="px-4 px-md-6 mt-md-4 mt-0">
                                        <v-col cols="4" class="pb-0" v-for="(value, key) in newtimelindata" :key="key">
                                            <v-text-field class="funda-field" readonly color="#000" :label="key"
                                                :value="value ? value : '---'"></v-text-field>
                                        </v-col>

                                    </v-row>
                                </div> -->


                                <template>
                                    <!-- {{ timelineEvents }} -->
                                    <div class="timeline-horizontal mt-3 ml-6">
                                        <div v-for="(event, index) in timelineEvents" :key="index"
                                            :class="['event', event.status]">
                                            <span
                                                :class="['dot', event.status === 'completed' ? 'completed-dot' : 'dotnot']">
                                                <v-icon  size="25"
                                                    :style="{ color: event.status === 'completed' ? '#2196f3' : 'grey' }"> {{ event.status === 'completed' ? 'mdi-check-circle' : 'mdi-circle' }}</v-icon>
                                            </span>
                                            <span class="timeheadd mt-3">{{ event.name }}</span>
                                            <span class="timelinevalue">{{ event.datess }}</span>
                                        </div>
                                    </div>
                                </template>


                            </div>
                        </v-card>






                        <v-card v-if="singlepagedata.script_data.IPO_Subscription_Status"
                            class="ss-cards overflow-hidden mb-md-6" width="100%">
                            <div class="py-3 py-md-6">
                                <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent" dense>
                                    <v-list-item class="px-0">
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold title mb-2">IPO Subscription
                                                Status</v-list-item-title>

                                        </v-list-item-content>
                                    </v-list-item>
                                </v-toolbar>

                                <div>
                                    <v-row class="px-4 px-md-6 mt-md-4 mt-0">
                                        <v-col cols="4" class="pb-0"
                                            v-for="(t, l) in singlepagedata.script_data.IPO_Subscription_Status"
                                            :key="l">
                                            <v-text-field class="funda-field" readonly color="#000"
                                                :label="t['Category']"
                                                :value="t['Subscription (times)'] ? t['Subscription (times)'] : '---'">"></v-text-field>
                                        </v-col>

                                    </v-row>
                                </div>



                            </div>
                        </v-card>

                        <!-- <v-card class="ss-cards overflow-hidden mb-md-6" width="100%">
                            <div class="py-3 py-md-6">
                                <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent" dense>
                                    <v-list-item class="px-0">
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold title mb-2">IPO
                                                Reservation</v-list-item-title>

                                        </v-list-item-content>
                                    </v-list-item>
                                </v-toolbar>

                                <v-row class="px-4 px-md-6 mt-md-4 mt-2">
                                    <v-col cols="4" class="pb-0"
                                        v-for="(t, l) in singlepagedata.script_data.IPO_Reservation" :key="l">
                                        <v-text-field class="funda-field" readonly color="#000"
                                            :label="t['Investor Category']"
                                            :value="t['Shares Offered'] ? t['Shares Offered'] : '---'">
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                            </div>
                        </v-card> -->

                        <v-card class="ss-cards overflow-hidden mb-md-6" width="100%"
                            v-if="singlepagedata.script_data.IPO_Financial_Information">
                            <div class="py-3 py-md-6">
                                <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent" dense>
                                    <v-list-item class="px-0">
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold title mb-2">Financial
                                                Information</v-list-item-title>

                                        </v-list-item-content>
                                    </v-list-item>
                                </v-toolbar>


                                <v-data-table mobile-breakpoint item-key="name" must-sort hide-default-footer
                                    fixed-header class="overflow-hiddens ipodatatablesty tablehedersig rounded-0"
                                    :headers="financialheader" :search="financialsearch"
                                    :items="singlepagedata.script_data.IPO_Financial_Information" :items-per-page="-1">

                                    <template v-slot:body="{ items }">
                                        <tbody v-if="singlepagedata.script_data.IPO_Financial_Information">
                                            <tr class="table-row" v-for="(item, o) in items" :key="o">
                                                <td
                                                    v-if="item.Assets || item['Net Worth'] || item['Period Ended'] || item['Profit After Tax'] || item['Reserves and Surplus'] || item['Revenue'] || item['Total Borrowing']">
                                                    <p class="fs-14 font-weight-medium mb-0 txt-000 mt-2">
                                                        {{ item['Assets'] ? item['Assets'] : "" }}
                                                    </p>
                                                </td>
                                                <td
                                                    v-if="item.Assets || item['Net Worth'] || item['Period Ended'] || item['Profit After Tax'] || item['Reserves and Surplus'] || item['Revenue'] || item['Total Borrowing']">
                                                    <p class="fs-14 text-right font-weight-medium mb-0 txt-000 mt-2">
                                                        {{ item['Net Worth'] ? item['Net Worth'] : "" }}
                                                    </p>
                                                </td>
                                                <td
                                                    v-if="item.Assets || item['Net Worth'] || item['Period Ended'] || item['Profit After Tax'] || item['Reserves and Surplus'] || item['Revenue'] || item['Total Borrowing']">
                                                    <p class="fs-14 text-right font-weight-medium mb-0 txt-000 mt-2">
                                                        {{ item['Period Ended'] ? item['Period Ended'] : "" }}
                                                    </p>
                                                </td>
                                                <td
                                                    v-if="item.Assets || item['Net Worth'] || item['Period Ended'] || item['Profit After Tax'] || item['Reserves and Surplus'] || item['Revenue'] || item['Total Borrowing']">
                                                    <p class="fs-14 text-right font-weight-medium mb-0 txt-000 mt-2">
                                                        {{ item['Profit After Tax'] ? item['Profit After Tax'] : "" }}
                                                    </p>
                                                </td>
                                                <td
                                                    v-if="item.Assets || item['Net Worth'] || item['Period Ended'] || item['Profit After Tax'] || item['Reserves and Surplus'] || item['Revenue'] || item['Total Borrowing']">
                                                    <p class="fs-14 text-right font-weight-medium mb-0 txt-000 mt-2">
                                                        {{ item['Reserves and Surplus'] ? item['Reserves and Surplus'] :
                                                            "" }}
                                                    </p>
                                                </td>
                                                <td
                                                    v-if="item.Assets || item['Net Worth'] || item['Period Ended'] || item['Profit After Tax'] || item['Reserves and Surplus'] || item['Revenue'] || item['Total Borrowing']">
                                                    <p class="fs-14 text-right font-weight-medium mb-0 txt-000 mt-2">
                                                        {{ item['Revenue'] ? item['Revenue'] : "" }}
                                                    </p>
                                                </td>
                                                <td
                                                    v-if="item.Assets || item['Net Worth'] || item['Period Ended'] || item['Profit After Tax'] || item['Reserves and Surplus'] || item['Revenue'] || item['Total Borrowing']">
                                                    <p class="fs-14 text-right font-weight-medium mb-0 txt-000 mt-2">
                                                        {{ item['Total Borrowing'] ? item['Total Borrowing'] : "" }}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else class="text-center">
                                            <td :colspan="financialheader.length" class="text-center">
                                                <div class="mx-auto pa-16 ">
                                                    <img class="align-self-stretch mx-auto" width="100px"
                                                        :src="require('@/assets/no data folder.svg')" />
                                                    <h4 class="txt-999 font-weight-regular">There is no data here yet!
                                                    </h4>
                                                </div>
                                            </td>
                                        </tbody>
                                    </template>
                                </v-data-table>


                            </div>
                        </v-card>


                        <v-card class="ss-cards overflow-hidden mb-md-6" width="100%">
                            <div class="py-3 py-md-6">
                                <v-toolbar class="nav-drawer elevation-0 px-md-2 px-0" color="transparent" dense>
                                    <v-list-item class="px-0">
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold title mb-2">About the
                                                company</v-list-item-title>

                                        </v-list-item-content>
                                    </v-list-item>
                                </v-toolbar>

                                <v-row class="px-4 px-md-6 mt-md-4 mt-2">
                                    <v-col cols="12" class="pb-0">
                                        <p v-html="singlepagedata.script_data.summary" class="fs-12  txt-666 txt-x-j">
                                        </p>
                                    </v-col>
                                </v-row>

                            </div>
                        </v-card>




                    </div>
                </v-col>
                <v-col cols="12" md="3" class="pr-md-0 pt-0 pt-md-3 singlepageipofixed">
                    <div class="pos-md-stk top-md-120 mt-4 mr-4 px-4 px-md-0">
                        <v-card outlined
                            class="overflow-hidden ss-login-card pt-md-3 px-3 rounded-lg text-center pos-md-rlt"
                            width="100%">
                            <img src="@/assets/product/ipos/main-bg.png" alt="Stocks-ETFs"
                                class="mb-md-2 mt-md-4 px-md-4 px-16" width="100%">
                            <p class="font-weight-bold fs-16 mb-1">Ready to invest in Ipo?</p>
                            <p class="txt-666 font-weight-medium fs-12 mb-8">Open your demat account
                                effortlessly <br> with our six-click
                                process.
                            </p>
                            <v-card class="pos-md-abs pt-6 pt-md-0 btm-32 elevation-0 rounded-0" width="100%">
                                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()"
                                    class="pr-md-6">

                                    <v-text-field v-model="mobile" @keypress="NumberValid($event)"
                                        @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
                                        hide-spin-buttons flat hide-details solo background-color="#F1F3F8"
                                        class="sign-up-filed elevation-0 rounded-pill caption mb-3"
                                        label="Enter mobile number to begin">
                                        <template #prepend-inner>
                                            <img src="@/assets/phone-icon.svg" width="100%" alt="phone-icon">
                                        </template>
                                    </v-text-field>

                                    <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07E30'"
                                        class="text-none rounded-pill elevation-0"><span
                                            class="black--text font-weight-bold">Invest
                                            now</span></v-btn>
                                </v-form>
                            </v-card>

                            <!-- <img src="@/assets/sstk/ssimgb.png" alt="ssimgb" class="pos-abs btm-0 rig-0"
                                                width="100%"> -->
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- <div class="sec-footer">
                <FooterSet />
            </div> -->
    </div>
</template>

<script>

export default {
    data() {
        return {
            singlepagedata: [],
            numberis: [
                (v) => !!v || "Your mobile number is required",
                (v) =>
                    /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number",
            ],
            detailsToDisplay: [
                "IPO Start Date",
                "IPO End Date",
                "Basis of Allotment",
                "Initiation of Refunds",
                "Issue Type",
                "Listing At",
                "Share holding pre issue",
            ],
            details: {},
            financialsearch: '',

            financialheader: [
                { text: 'Period Ended', value: 'Period Ended', sortable: false, width: "10%", },
                { text: 'Assets', value: 'Assets', sortable: false, align: 'right', width: "12%", },
                { text: 'Net Worth', value: 'Net Worth', sortable: false, align: 'right', width: "15%", },
                { text: 'Profit After Tax', value: 'Profit After Tax', sortable: false, align: 'right', width: "15%", },
                { text: 'Reserves and Surplus', value: 'Reserves and Surplus', sortable: false, align: 'right', width: "15%", },
                { text: 'Revenue', value: 'Revenue', sortable: false, align: 'right', width: "15%", },
                { text: 'Total Borrowing', value: 'Total Borrowing', sortable: false, align: 'right', width: "20%", },
            ],
            valid: true,
            mobile: '',
            screenloader: true,
            newtimelindata: {},
            ipocalreturn: '',
            timeline: [
                { title: 'Event 1', date: '2023-01-01' },
                { title: 'Event 2', date: '2023-03-15' },
                { title: 'Event 3', date: '2023-06-10' },
                { title: 'Event 4', date: '2023-09-20' },
            ],
            timelineEvents: [],
            setId: ''
        }
    },
    methods: {

        sinpageapi() {
            this.screenloader = true
            this.singlepagedata = []
            this.details = {}
            this.newtimelindata = {}
            this.ipocalreturn = ''
            const axios = require('axios');
            let data = JSON.stringify({
                // "company_name": 'Apex Ecotech Limited'
                "company_name": this.routename.best

            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://v3.mynt.in/ipo/get_single_ipo_data',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosthis = this
            axios.request(config)
                .then((response) => {
                    axiosthis.screenloader = false

                    if (response.data.data == 'no data') {
                        axiosthis.$router.push("/ipos");
                    }
                    else {

                        axiosthis.singlepagedata = response.data.data;

                        // axiosthis.ipocalreturn = axiosthis.singlepagedata.BSE !== 'no data' ? (axiosthis.singlepagedata.BSE.ClsPric - axiosthis.singlepagedata.BSE.OpnPric) : axiosthis.singlepagedata.NSE !== 'no data' ? (axiosthis.singlepagedata.NSE.ClsPric - axiosthis.singlepagedata.BSE.OpnPric) : axiosthis.singlepagedata['Stock Exchanges'] === 'BSE, NSE' ? (axiosthis.singlepagedata.NSE.ClsPric - axiosthis.singlepagedata.BSE.OpnPric) : '' 


                        if (axiosthis.singlepagedata.NSE && axiosthis.singlepagedata.NSE !== 'no data') {

                            axiosthis.ipocalreturn = (axiosthis.singlepagedata.NSE.OpnPric - axiosthis.singlepagedata.NSE.ClsPric).toFixed(2);
                        } else if (axiosthis.singlepagedata.BSE && axiosthis.singlepagedata.BSE !== 'no data') {

                            axiosthis.ipocalreturn = (axiosthis.singlepagedata.BSE.OpnPric - axiosthis.singlepagedata.BSE.ClsPric).toFixed(2);
                        } else if (axiosthis.singlepagedata['Stock Exchanges'] === 'BSE, NSE') {

                            axiosthis.ipocalreturn = (axiosthis.singlepagedata.NSE.OpnPric - axiosthis.singlepagedata.BSE.ClsPric).toFixed(2);
                        } else {

                            axiosthis.ipocalreturn = '0.00-';
                        }
                        // console.log('fbf', typeof axiosthis.ipocalreturn);
                        // console.log('111',  axiosthis.ipocalreturn);




                        const ipoDetails = axiosthis.singlepagedata.script_data.IPO_Details || [];
                        const ipoTimeline = axiosthis.singlepagedata.script_data.IPO_Timeline || [];
                        let subscriptibdet = [];
                        if (axiosthis.singlepagedata.script_data.IPO_Subscription_Status) {
                            subscriptibdet = axiosthis.singlepagedata.script_data.IPO_Subscription_Status;
                        }

                        const allDetails = [...ipoDetails, ...ipoTimeline, ...subscriptibdet];

                        const ipoTimelineDetails = {
                            "IPO Start Date": allDetails.find(detail => detail.name === "IPO Start Date")?.value,
                            "IPO End Date": allDetails.find(detail => detail.name === "IPO End Date")?.value,
                            "Listing Date": ipoTimeline.find(detail => detail.name === "Listing Date")?.value,
                            "Basis of Allotment": allDetails.find(detail => detail.name === "Basis of Allotment")?.value,
                            "Initiation of Refunds": allDetails.find(detail => detail.name === "Initiation of Refunds")?.value,
                            "Credit of Shares to Demat": allDetails.find(detail => detail.name === "Credit of Shares to Demat")?.value,
                            "Face Value": allDetails.find(detail => detail.name === "Face Value")?.value,
                            "Price Band": allDetails.find(detail => detail.name === "Price Band")?.value,
                            "Lot Size": allDetails.find(detail => detail.name === "Lot Size")?.value,
                            "Total Issue Size": allDetails.find(detail => detail.name === "Total Issue Size")?.value,
                            "Fresh Issue": allDetails.find(detail => detail.name === "Fresh Issue")?.value,
                            "Offer for Sale": allDetails.find(detail => detail.name === "Offer for Sale")?.value,
                            "Issue Type": allDetails.find(detail => detail.name === "Issue Type")?.value,
                            "Listing At": allDetails.find(detail => detail.name === "Listing At")?.value,
                            "Share holding previous issue": allDetails.find(detail => detail.name === "Share holding pre issue")?.value,
                            "Share holding Post issue": allDetails.find(detail => detail.name === "Share holding post issue")?.value,
                            "Market Maker portion": allDetails.find(detail => detail.name === "Market Maker portion")?.value,
                        };

                        axiosthis.details = {
                            ipoTimelineDetails: ipoTimelineDetails,
                        };

                        axiosthis.newtimelindata = {
                            "IPO Start Date": allDetails.find(detail => detail.name === "IPO Start Date")?.value,
                            "IPO End Date": allDetails.find(detail => detail.name === "IPO End Date")?.value,
                            "Basis of Allotment": allDetails.find(detail => detail.name === "Basis of Allotment")?.value,
                            "Initiation of Refunds": allDetails.find(detail => detail.name === "Initiation of Refunds")?.value,
                            "Credit of Shares to Demat": allDetails.find(detail => detail.name === "Credit of Shares to Demat")?.value,
                            "Listing Date": ipoTimeline.find(detail => detail.name === "Listing Date")?.value,
                        }
                        console.log(axiosthis.newtimelindata);
                        this.processResponse()

                    }



                })
                .catch((error) => {
                    console.log(error);
                    axiosthis.screenloader = false
                    axiosthis.$router.push("/ipos");

                });
        },
        processResponse() {
            const today = new Date();
            this.timelineEvents = Object.keys(this.newtimelindata).map((key) => {
                const eventDate = new Date(this.newtimelindata[key]);
                const status =
                    eventDate < today
                        ? "completed"
                        : eventDate.toDateString() === today.toDateString()
                            ? "current"
                            : "not-achieved";

                return {
                    name: key,
                    status,
                    datess: this.newtimelindata[key],
                };
            });
        }
        ,



        NumberValid($event) {
            if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
                // 0-9 only
            } else {
                $event.preventDefault();
            }
        },
        getCall() {
            if (this.$refs.form.validate() == true) {

                window.open(
                    `https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`
                );
                this.$refs.form.reset();

            }
        },
    },



    mounted() {
        this.screenloader = true
        const routeParams = this.$route.params
        this.routename = routeParams
        // console.log('routeParams', this.routename);

        this.sinpageapi()
        var styleElement = document.createElement("style");
        styleElement.appendChild(document.createTextNode("::-webkit-scrollbar {width: 0px; height:0px} *, html, body, .v-application, .v-application .display-1, .v-application .headline, .v-application .title, .v-application .subtitle-1, .v-application .subtitle-2, .v-application .body-1, .v-application .body-2, .v-application .caption, .v-application .overline { font-family: 'Inter var' !important; }"));
        document.getElementsByTagName("head")[0].appendChild(styleElement);


    }
}
</script>

<style scoped>
.TL-wrapper {
    padding: 14px;
    border: 2px solid #ccc;
    margin-bottom: 14px;
}

.timeline-horizontal {
    display: flex;
}

.event {
    flex: 1 0 120px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.event .dot {
    display: inline-block;
    width: 23px;
    height: 23px;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

.event:not(:last-child):after {
    content: "";
    border: 1px solid #ccc;
    position: absolute;
    width: calc(100% - 18px);
    top: 7px;
    left: 17px;
    margin-top: 6px;
    margin-left: 5px;

}

.event .stage-name {
    max-width: max(0px, calc((100% - 120px)*999));
    overflow: hidden;
}

.contained-in-width {
    width: 400px;
}

.event>div {
    width: 100%;
    display: flex;
}

.event.completed .dot {
    border-color: #ffffff;

}

.event.completed:not(:last-child):after {
    border-color: #2196f3;
    margin-top: 6px;
    margin-left: 3px;
}

.event.current .dot {
    border-color: #2196f3;
    background-color: #2196f3;


}

.event.current .stage-name {
    max-width: none;
    overflow: initial;
}
</style>