<template>
    <div class="no-xhide">
        <v-snackbar class="snakbar-sty pt-6 d-md-none pr-6 z-i6 rounded-pill" transition="slide-x-reverse-transition"
            v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            {{ snackmsgbar }}
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10"
            transition="slide-x-reverse-transition" top right v-model="snackbar" timeout="4000" :value="true"
            :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            {{ snackmsgbar }}
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <div data-aos="fade-up" class="pt-4 pb-12">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl pos-rlt d-none d-md-block">
                <p class="white--text fs-50 lh-48 font-weight-bold mb-5">Invest Smarter, Grow Faster

                </p>
                <p class="white--text lh-28 title">
                    Experience the advantage of professional investment management with Zebu’s <br> Portfolio Management
                    Services (PMS – distributor). Our seasoned experts <br> work diligently to optimize and grow your
                    investments.

                </p>



                <p class="font-weight-regular mt-2 mb-1 fs-14 white--text">Start your journey today with a minimum
                    investment of<a target="_blank" rel="noopener noreferrer" style="text-decoration: none;"
                        class="ml-1 white--text font-weight-medium" href="https://oa.mynt.in/?ref=pmslp"> ₹50 Lakhs.
                    </a></p>

                <div class="pos-abs prd-ipo-img text-right">
                    <img alt="main-bg.png" src="@/assets/product/lppms/PMS.svg" width="100%" />
                </div>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <p class="white--text fs-38 font-weight-bold mb-6">Invest Smarter, Grow Faster
                </p>
                <p class="white--text fs-18">
                    Experience the advantage of professional investment management with Zebu’s Portfolio Management
                    Services (PMS – distributor). Our seasoned experts work diligently to optimize and grow your
                    investments.<br>
                    tart your journey today with a minimum
                    investment of ₹50 Lakhs.
                </p>

                <!-- <p class="font-weight-regular mt-2 mb-1 fs-14 white--text">Don't have an account yet? <a target="_blank"
                        rel="noopener noreferrer" class="mailto-txt1 font-weight-medium"
                        href="https://oa.mynt.in/?ref=pmslp">Sign up
                    </a></p> -->
            </v-card>
        </div>

        <div class="px-3 px-sm-0 d-none d-md-block  mb-15">
            <div class="px-16 pb-3 ">
                <p class="font-weight-medium black--text"><span class="display-1">Empower Your Portfolio with Zebu

                    </span></p>
                <p class="txt-444 font-weight-light title lh-28">PMS is a personalized investment solution designed for
                    High Net Worth Individuals (HNIs) and Ultra HNIs, focusing on strategic allocation in stocks, bonds,
                    mutual funds, and other securities. With Zebu’s seasoned fund managers, your portfolio aligns with
                    your financial goals and risk tolerance for optimal growth.


                </p>

                <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws-pmslp" height="48px"
                    color="#2A2A2A" class="text-none rounded-pill elevation-0 mb-10 mt-4"><span
                        class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>
            </div>
            <v-card data-aos="fade-down" class="box-s111 mx-16 elevation-0 rounded-xl   mb-10 pt-16 pb-10 " outlined>
                <div class="pos-rlt ">
                    <v-row no-glutters>
                        <v-col cols="6">
                            <div class="px-16">
                                <p class="subtitle-2 text-uppercase">What's special</p>
                                <p class="secondary-font mb-8">
                                    Benefits of Services <br> via Zebu
                                </p>
                                <v-card @click="changeTois(l)" v-for="(l, k) in datasspl" :key="k" data-aos="fade-down"
                                    :color="activelist.id == k ? '#444444' : '#ffffff'" width="100%"
                                    class="box-s1 brd-r-16 py-3 px-6 mb-4">
                                    <p class="mb-0 title font-weight-regular"
                                        :class="activelist.id == k ? 'white--text' : 'black--text'">{{
                                            l.tit }}</p>
                                </v-card>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div class="px-16">
                                <img data-aos="fade-up" :alt="activelist.image" :src="activelist.image" width="100%" />
                                <div data-aos="fade-down">
                                    <p class="font-weight-medium headline black--text">{{ activelist.tit }}</p>
                                    <p class="font-weight-light mt-3 black--text fs-17">{{ activelist.sub }}</p>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <div class="px-16 pt-13  ">
                <v-card width="100%" data-aos="fade-down" class="box-s1 elevation-0 rounded-xl ">
                    <p class="mb-4 fs-24 text-center mb-5 black--text font-weight-medium">
                        "Perks of Zebu’s Portfolio Management Solutions"
                    </p>

                    <v-row justify="center" class="mt-3">
                        <v-col cols="12" md="4" class=" " v-for="(l, k) in steps" :key="k">
                            <v-card data-aos="flip-up" width="100%" class="box-s1  elevation-0 px-5 py-5 rounded-xl "
                                outlined>
                                <div class="text-center">
                                    <v-img class="mb-3 text-center" :alt="l.image" width="60px" height="60px"
                                        :src="l.image"></v-img>
                                </div>
                                <p class="mb-0 bleck--text f-14 font-weight-regular">{{ l.sub }}</p>
                                <p class="mb-0 grey--text f-12 font-weight-regular">{{ l.tit }}</p>
                            </v-card>

                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <div data-aos="fade-down" class="secfive-bg rounded-xl mt-4 mb-4  px-lg-14 pt-14  box-s1x elevation-0 "
                width="100%">

                <p class="mb-0 fs-24  text-center  black--text font-weight-medium">
                    Zebu empaneled with
                </p>
                <div data-aos="flip-up" class="sec-four mt-8  py-0 py-md-2">
                    <v-card class="pt-2 pb-1 px-md-3 elevation-0" width="100%">

                        <div class=" text-center">
                            <v-row no-glutters>
                                <v-col cols="2"></v-col>
                                <v-col cols="2" v-for="logo in insparlogo" :key="logo.id">
                                   <v-card elevation="0">
                                        <v-img width="190" height="100" :alt="logo.image" :src="logo.image"></v-img>
                                    </v-card>
                                </v-col>
                                <v-col cols="2"></v-col>

                            </v-row>

                            <!-- <span v-for="logo in insparlogo" :key="`${logo.id}-duplicate`" class="slide">
                        <v-img width="190" height="100" :alt="logo.image" :src="logo.image"></v-img>
                    </span> -->
                        </div>

                    </v-card>
                </div>
            </div>
            <div class="px-6 mt-14 px-sm-8 px-md-16 mb-15">
                <v-card width="100%" data-aos="fade-down" color="#E5EBEC"
                    class="box-s1 elevation-0 rounded-xl pt-8 pb-10 d-none d-md-block" outlined>
                    <div class="pos-rlt">
                        <p class="mb-0 fs-24 text-center black--text font-weight-medium">Frequently Asked Questions</p>

                        <p class="font-weight-light text-center mt-2 black--text fs-18">Questions on your mind? Don't
                            worry we have
                            the
                            answers!</p>
                        <div v-if="onlinepay != ''">
                            <v-expansion-panels dense v-model="panelselecte"
                                class="elevation-0 rounded-0 pl-8 mt-2 pr-8 mb-md-0">
                                <v-expansion-panel class="bgstyle" v-for="(p, h) in onlinepay" :key="h">
                                    <v-expansion-panel-header @click="setScrollwindow()"
                                        class="font-weight-bold bgstyle py-0 ml-md-0">
                                        <span class="fs-18">{{ h + 1 }}. {{ p.attributes.question }}</span>
                                        <template v-slot:actions>
                                            <v-icon class="d-none d-md-block">mdi-chevron-down</v-icon>
                                            <v-icon class="d-md-none">mdi-chevron-down</v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="bgstyle">
                                        <p class="mb-0 ml-5 fs-17 font-weight-light" v-html="p.attributes.answer"></p>
                                    </v-expansion-panel-content>
                                    <v-divider></v-divider>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                        <div v-else class="mx-auto text-center ">
                            <img class="align-self-stretch mx-auto" width="100px"
                                :src="require('@/assets/no data folder.svg')" />
                            <h4 class="txt-999 font-weight-regular">There is no data here yet!</h4>
                        </div>
                    </div>
                </v-card>
            </div>
            <div class="py-md-16 rounded-xl">
                <v-card data-aos="fade-down" class="rounded-0 pb-10 mb-10 px-sm-3 elevation-0 d-md-none" width="100%">
                </v-card>
                <div data-aos="fade-up" class="secineight-bg mb-8 mb-md-0">
                    <v-card color="transparent"
                        class="secineight-card mt-16 pos-rlt mx-auto brd-r-32 elevation-0 d-none d-md-block"
                        width="90%">
                        <img src="@/assets/sec-eight-bg.webp" width="100%" alt="sec-eight-bg" />

                        <div class="seceight-ctn pos-abs">
                            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
                                <v-row no-gutters class="pt-10">
                                    <v-col cols="9" class="pr-4">
                                        <v-text-field v-model="mobile" @keypress="NumberValid($event)"
                                            @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
                                            hide-spin-buttons flat solo background-color="#595959"
                                            class="menu-field-seceight elevation-0 mr-3 rounded-pill caption txt-field"
                                            label="Enter mobile number to begin">
                                            <template #prepend-inner>
                                                <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2"
                                                    alt="phone-icon" />
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-btn type="submit" block height="48px"
                                            :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                                            class="text-none rounded-pill elevation-0"><span
                                                class="black--text font-weight-bold">Sign
                                                up</span></v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </v-card>
                    <v-card color="#282828" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-md-none pa-6"
                        width="90%">
                        <img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg" />
                        <!-- <v-img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg"></v-img> -->

                        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
                            <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()"
                                :maxlength="10" :rules="numberis" required hide-spin-buttons flat solo
                                background-color="#595959"
                                class="menu-field-seceight elevation-0 rounded-pill caption txt-field"
                                label="Enter mobile number to begin">
                                <template #prepend-inner>
                                    <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2"
                                        alt="phone-icon" />
                                </template>
                            </v-text-field>
                            <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                                class="text-none rounded-pill elevation-0"><span
                                    class="black--text font-weight-bold">Sign
                                    up</span></v-btn>
                        </v-form>
                    </v-card>
                </div>
            </div>
        </div>

        <div class="d-md-none px-3">
            <p class="subtitle-2 text-uppercase">What's special</p>
            <p class="secondary-fn fs-28 mb-8 lh-32">Invest in innovative firms in India.</p>
            <div v-for="(l, k) in datasspl" :key="k" :class="k != datasspl.length - 1 ? 'mb-16' : ''">
                <img data-aos="fade-up" :alt="l.image" :src="l.image" width="100%" />
                <div data-aos="fade-down">
                    <p class="font-weight-medium fs-24 black--text">{{ l.tit }}</p>
                    <p class="font-weight-light black--text fs-18">{{ l.sub }}</p>
                </div>
            </div>

            <v-card width="100%" data-aos="fade-down" class="box-s1 elevation-0 rounded-xl pt-8">
                <p class="mb-0 fs-24 text-center mb-5 black--text font-weight-medium">
                    Why Partner With Zebu?


                </p>

                <v-row justify="center">
                    <v-col cols="12" md="4" class="" v-for="(l, k) in steps" :key="k">
                        <v-card data-aos="flip-up" width="100%" class="box-s1 elevation-0 px-3 py-3 rounded-xl "
                            outlined>


                            <div class="text-center">

                                <v-img class="mb-3 text-center" :alt="l.image" width="60px" height="60px"
                                    :src="l.image"></v-img>
                            </div>


                            <p class="mb-0 bleck--text f-14 font-weight-regular">{{ l.sub }}</p>

                            <p class="mb-0 grey--text f-12 font-weight-regular">{{ l.tit }}</p>



                        </v-card>

                    </v-col>
                </v-row>
            </v-card>

            <div data-aos="fade-down" class="secfive-bg rounded-xl px-lg-14 pt-14  box-s1x elevation-0 " width="100%">

                <p class="mb-0 fs-24  text-center  black--text font-weight-medium">
                    Zebu empaneled with
                </p>
                <div data-aos="flip-up" class="sec-four mt-8  py-0 py-md-2">
                    <v-card class="pt-2 pb-1 px-md-3 elevation-0" width="100%">
                        <div class="wrapper">
                            <div class="slider">
                                <div class="slide-track">
                                    <span v-for="logo in insparlogo" :key="logo.id" class="slide">
                                        <v-img width="190" height="100" :alt="logo.image" :src="logo.image"></v-img>
                                    </span>
                                    <span v-for="logo in insparlogo" :key="`${logo.id}-duplicate`" class="slide">
                                        <v-img width="190" height="100" :alt="logo.image" :src="logo.image"></v-img>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </div>
            </div>

            <div class="pt-8 pb-6">
        <p class="mb-0 fs-24 ml-8 black--text font-weight-medium">Frequently Asked Questions</p>
  
        <p class="font-weight-light ml-8 mt-3 black--text fs-17">Questions on your mind? Don't worry we have the answers!
        </p>
        <div v-if="onlinepay != ''">
        <v-expansion-panels dense v-model="panelselecte" class="elevation-0 rounded-0 mb-6 ml-3 mb-md-0">
          <v-expansion-panel class="exp-sty" v-for="(p, h) in onlinepay" :key="h">
            <v-expansion-panel-header @click="setScrollwindow()" class="font-weight-bold subtitle-1 py-0 ml-md-3">{{ h + 1
              }}.
              {{ p.attributes.question }}
              <template v-slot:actions>
                <v-icon class="d-none d-md-block"></v-icon>
                <v-icon class="d-md-none">mdi-chevron-down</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="mb-0 font-weight-light" v-html="p.attributes.answer"></p>
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div v-else class="mx-auto text-center ">
                  <img class="align-self-stretch mx-auto" width="100px" :src="require('@/assets/no data folder.svg')" />
                  <h4 class="txt-999 font-weight-regular">There is no data here yet!</h4>
                </div>
      </div>

      <v-card color="#282828" class="secineight-card pos-rlt mx-auto mb-12 mt-4 brd-r-32 elevation-0 d-md-none pa-6"
                    width="97%">
                    <img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg" />
                    <!-- <v-img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg"></v-img> -->

                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
                        <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()"
                            :maxlength="10" :rules="numberis" required hide-spin-buttons flat solo
                            background-color="#595959"
                            class="menu-field-seceight elevation-0 rounded-pill caption txt-field"
                            label="Enter mobile number to begin">
                            <template #prepend-inner>
                                <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon" />
                            </template>
                        </v-text-field>
                        <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                            class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                                up</span></v-btn>
                    </v-form>
                </v-card>
        </div>

    </div>
</template>

<script>


export default {
    data: () => ({


        learnitems: [

            {
                title: 'Focused Portfolio',
                image: require('@/assets/product/lpins/pd (4).svg'),
                subtitle: 'Handpicked 15-25 high-potential companies for superior long-term growth.',
            },
            {
                title: 'Direct Ownership',
                image: require('@/assets/product/lpins/client1.svg'),
                subtitle: 'Stocks held directly in your Demat account for transparency and control',

            },

            {
                title: 'Shareholder Benefits',
                image: require('@/assets/product/lpins/pd (3).svg'),
                subtitle: 'Enjoy dividends, corporate actions, and price appreciation.',

            },
            {
                title: 'Expert Monitoring',
                image: require('@/assets/product/lpins/pd (4).svg'),
                subtitle: 'Continuous tracking to manage risks and enhance returns.',
            },
            {
                title: 'Paperless Onboarding',
                image: require('@/assets/product/lpins/client1.svg'),
                subtitle: 'Seamless, hassle-free login process.',

            },

            {
                title: 'Fund Manager Access',
                image: require('@/assets/product/lpins/pd (3).svg'),
                subtitle: 'Exclusive calls to discuss strategies and queries.',

            },

        ],
        detailitem: [
            {
                image: require('@/assets/product/lpins/detailins.svg'),

                title: "Min Investment: Rs. 50 Lakhs"
            },
            {
                image: require('@/assets/product/lpins/planins.svg'),

                title: "Dedicated Team of Experts"
            },
            {
                image: require('@/assets/product/lpins/displan.svg'),

                title: "Over 25 Years of Trusted Service"
            }
        ],

        product_mob: [
            { img: require('@/assets/product/p1.png'), txt: 'Access to Diversified and High-Growth Investment Options' },
            { img: require('@/assets/product/p2.png'), txt: 'Personalized Support from Dedicated Relationship Managers' },
            { img: require('@/assets/product/p3.png'), txt: 'Expert-Led Regular Portfolio Tracking and Insights ' },
            { img: require('@/assets/product/p4.png'), txt: 'Managed by Zebu’s Expert and Award-Winning Fund Managers' },

        ],
        steps111: [
            { id: 0, image: require("@/assets/product/lppartner/prs.svg"), tit: "Focused Portfolio", sub: "Handpicked 15-25 high-potential companies for superior long-term growth." },
            { id: 1, image: require("@/assets/product/lppartner/prs.svg"), tit: "Direct Ownership", sub: "Stocks held directly in your Demat account for transparency and control." },
            { id: 2, image: require("@/assets/product/lppartner/prs.svg"), tit: "Shareholder Benefits", sub: "Enjoy dividends, corporate actions, and price appreciation." },
            { id: 3, image: require("@/assets/product/lppartner/prs.svg"), tit: "Expert Monitoring", sub: "Continuous tracking to manage risks and enhance returns." },
            { id: 4, image: require("@/assets/product/lppartner/prs.svg"), tit: "Paperless Onboarding", sub: "Seamless, hassle-free login process." },
            { id: 5, image: require("@/assets/product/lppartner/prs.svg"), tit: "Fund Manager Access", sub: "Exclusive calls to discuss strategies and queries." },
        ],
        steps: [
            {
                id: 0,
                image: require("@/assets/product/lppartner/prs.svg"),
                tit: "With Zebu’s expert guidance, clients can aim for higher returns on their investments.",
                sub: "Potential for Higher Returns"
            },
            {
                id: 1,
                image: require("@/assets/product/lppartner/sob.svg"),
                tit: "Our seasoned fund managers leverage in-depth market insights, helping clients make more informed investment choices.",
                sub: "Data-Driven Decisions"
            },
            {
                id: 2,
                image: require("@/assets/product/lppartner/supp.svg"),
                tit: "Continuous tracking and strategic adjustments by our experts work to maximize returns and manage risk effectively.",
                sub: "Active Portfolio Monitoring"
            }
        ],


        datasspl: [
            { id: 0, image: require("@/assets/product/lppartner/tradetool.png"), tit: "Focused Portfolio", sub: "Handpicked 15-25 high-growth companies for superior returns." },
            { id: 1, image: require("@/assets/product/lppms/Try 2.svg"), tit: "Direct Ownership", sub: "Complete transparency with investments held in your Demat account." },
            { id: 2, image: require("@/assets/product/lppms/spl1.png"), tit: "Comprehensive Monitoring", sub: "Risk-managed and performance-enhanced portfolios." },
            { id: 3, image: require("@/assets/product/lppms/Try 2.svg"), tit: "Personalized Attention", sub: "Dedicated relationship managers and direct fund manager access." },
            { id: 4, image: require("@/assets/product/lppartner/growth.png"), tit: "Paperless Onboarding", sub: "Seamless and hassle-free investment setup." },

        ],
        activelist: {},
        tickitems: [
            { img: require("@/assets/abt-zebu-tick1.svg"), tit: 'Quick  Demat Account process', sub: 'Skip the hassle—invest in AIFs seamlessly with simple demat account.' },
            { img: require("@/assets/abt-zebu-tick2.svg"), tit: 'Exclusive Investment Opportunities', sub: 'Unlock access to unique, high-growth potential investment options curated by experts.' },
            { img: require("@/assets/abt-zebu-tick3.svg"), tit: 'True Diversification', sub: 'Broaden your portfolio with investments in real estate, private equity, commodities, distressed assets, and more.' },
            { img: require("@/assets/abt-zebu-tick4.svg"), tit: 'Flexible Investment Strategies', sub: 'Utilize advanced strategies like Long-Short and other complex methods with minimal regulatory restrictions.' },
        ],
        Investproducts: [
            {
                title: "Equity & Private Equity AIFs",
                mtitle: "Equity & Private Equity AIFs",
                image: require("@/assets/products-sec/Stocks-ETFs.png"),
                subtitle: "Invest in both listed and unlisted equity shares of companies across various growth stages with Zebu’s Equity AIFs.",

            },
            {
                title: "Multi-Factor AIF",
                mtitle: "Multi-Factor AIF",

                image: require("@/assets/products-sec/Futures-Options.png"),
                subtitle: "Gain exposure to the top 20-30 stocks selected through advanced factor analysis for optimal returns.",

            },
            {
                title: "Distressed Asset AIFs",
                mtitle: "Distressed Asset AIFs",

                image: require("@/assets/products-sec/Commodities.png"),
                subtitle: "Discover high-return fixed-income opportunities through debt financing for distressed companies.",

            },
            {
                title: "Real Estate AIFs",
                mtitle: "Real Estate AIFs",

                image: require("@/assets/products-sec/Commodities.png"),
                subtitle: "Access early-stage real estate investment opportunities with trusted and renowned builders through Zebu’s Real Estate AIFs.",

            },

        ],
        stepscat: [
            {
                id: 0,
                image: require("@/assets/product/lpins/in1.svg"),
                tit: "Access to Diversified and High-Growth Investment Options",
                sub: "Get Quote"
            },
            {
                id: 1,
                image: require("@/assets/product/lpins/in2.svg"),
                tit: "Personalized Support from Dedicated Relationship Managers",
                sub: "Smart Compare"
            },
            {
                id: 2,
                image: require("@/assets/product/lpins/in3.svg"),
                tit: "Expert-Led Regular Portfolio Tracking and Insights ",
                sub: "Get Quick"
            },
            {
                id: 3,
                image: require("@/assets/product/lpins/in3.svg"),
                tit: "Managed by Zebu’s Expert and Award-Winning Fund Managers ",
                sub: "Get Quick"
            }
        ],
        numberis: [(v) => !!v || "Your mobile number is required", (v) => /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number"],
        mobile:'',
        AuthorizedPerson: [
            {
                type: 'Criteria',
                "cat1": "<ul><li>Infra, Social, Non-banked Credit for SME</li><li>Special incentives provided by the government</li><li>Invest in start ups or early stage ventures or social ventures</li><li>Socially or economically desirable areas</li></ul>",
                "cat2": "<ul><li>Residual category of funds, invested primarily in unlisted securities</li></ul>",
                "cat3": "<ul><li>Funds which are liquid / listed equities</li><li>Funds which may invest in derivatives</li><li>Funds which employ leverage for investments</li></ul>",
            },
            {
                "type": "Type of Funds",
                "cat1": "<ul><li>Venture Capital Funds</li><li>SME Funds</li><li>Social Venture Funds</li><li>Infrastructure Funds</li></ul>",
                "cat2": "<ul><li>Private Equity Funds</li><li>Debt Funds</li><li>Funds of Funds</li><li>Other Funds (like Real Estate funds) not classified under the other two categories</li></ul>",
                "cat3": "<ul><li>Long Only Equity Funds</li><li>Long Short Equity Funds</li></ul>"
            },
            {
                "type": "Cons",
                "cat1": "<ul><li>Close ended</li><li>Not allowed to leverage</li><li>Specific investment restrictions for each sub type</li></ul>",
                "cat2": "<ul><li>Closed ended</li><li>Not allowed to leverage</li><li>No restrictions on asset allocation</li></ul>",
                "cat3": "<ul><li>Open or close ended</li><li>Leveraging is allowed up to 2x</li><li>No restrictions on asset allocation</li></ul>"
            },
            {
                "type": "Taxation",
                "cat1": "Pass through",
                "cat2": "Pass through",
                "cat3": "Fund level"
            }
        ],
        onlinepay: [],
        insparlogo: [
            {
                id: 1,
                image: require('@/assets/product/lppms/pmslogo (1).svg'),
            },
            {
                id: 2,
                image: require('@/assets/product/lppms/pmslogo (2).svg'),
            },
            {
                id: 3,
                image: require('@/assets/product/lppms/360logo.svg'),
            },
            {
                id: 4,
                image: require('@/assets/product/lppms/pmslogo (3).svg'),
            },
        ],
        cardTitles: [
            { title: 'Access to Diversified and High-Growth Investment Options' },
            { title: 'Personalized Support from Dedicated Relationship Managers' },
            { title: 'Expert-Led Regular Portfolio Tracking and Insights ' },
            { title: 'Managed by Zebu’s Expert and Award-Winning Fund Managers' },
        ],
        snackbarclr:'#000',
        snackbar:false,
        snackmsgbar:"",
        valid: false,


    }),
    methods: {

        ipofaqapi() {
            this.onlinepay = [];
            const axios = require("axios");

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://sess.mynt.in/strapi/pmsfaqs",
                headers: {},
            };
            var axiosThis = this;
            axios
                .request(config)
                .then((response) => {
                    axiosThis.onlinepay = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },


        loveTo() {
            const element = document.getElementById("love");
            element.scrollBy({
                left: -760,
                behavior: "smooth",
            });
        },

        loveBk() {
            const element = document.getElementById("love");
            element.scrollBy({
                left: 760,
                behavior: "smooth",
            });
        },
        changeTois(l) {
            this.activelist = l;
        },
        getCall() {
            if (this.$refs.form.validate() == true) {
                window.open(
                    `https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`
                );
                this.$refs.form.reset();
            }
        },
    },

    mounted() {
        this.activelist = this.datasspl[0];
        this.ipofaqapi()
    },


    computed: {
        localAttrs() {
            const attrs = {};

            if (this.variant === "default") {
                attrs.absolute = false;
                attrs.fixed = false;
            } else {
                attrs[this.variant] = true;
            }
            return attrs;
        },
    },
};
</script>
<style>
@media (min-width: 1920px) {

    /* Adjust breakpoint if necessary */
    .hide-on-xl {
        display: none !important;
    }
}
</style>